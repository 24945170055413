import korea from "vee-validate/dist/locale/ko.json";

class Constant {
  appName = "인하우스";
  validate = {
    language: {
      korea: korea,
    },
  };
}

export default new Constant();
